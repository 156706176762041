/**
 * The auth.guard.ts file contains 3 different types of guards for the following reasons:
 * We always want to display the home page (route "/" or "/home"), regardless of whether the user is authenticated or not.
 * However, we need to do additional check if the user is authenticated, hence the {@link authHomeGuard}.
 * {@link notAuthHomeGuard} protects the default route ("/").
 * {@link authHomeGuard} protects the "/home" route.
 * Both routes point to the start page, where the tiles are displayed.
 *
 * The user is redirected to "/home" when passing the {@link notAuthHomeGuard} for further processing.
 * The user is redirected to "/" if when failing the {@link authHomeGuard} to see start page.
 * The user is redirected to "/error/unauthorized" when failing the {@link authGuard}.
 */

import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { first, map } from "rxjs";
import { Utils } from "@premium-portal/types";
import { AuthenticationService } from "../../authentication";
import { RoutingPath } from "../../../shared/constants/routing-path";

/**
 * Use as a global route protection without custom handling
 *  - Redirect to unauthenticated page if the user is not authenticated
 *  - Deactivate the route if the auto-login flow has started or the user is not authenticated
 * @param activatedRoute
 */
export const authGuard: CanActivateFn = (activatedRoute: ActivatedRouteSnapshot) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  return authService.isUserAuthenticated().pipe(
    first(),
    map((isAuthenticated) => {
      if (isAuthenticated) return true;

      if (autoLogin(authService, activatedRoute)) {
        return false;
      }

      router.navigate([RoutingPath.ERROR_UNAUTHORIZED]);
      return false;
    })
  );
};

/**
 * Use for allowing an unauthenticated user to access the start-page
 *  - Redirect to "/home" route protected by {@link authHomeGuard} if the user is authenticated
 *  - Block the access to the current route if the auto-login flow has started
 *  - Load the current route otherwise
 * @param activatedRoute
 */
export const notAuthHomeGuard: CanActivateFn = (activatedRoute: ActivatedRouteSnapshot) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  return authService.isUserAuthenticated().pipe(
    first(),
    map((isAuthenticated) => {
      if (isAuthenticated) {
        router.navigate(["/home"]);
        return false;
      }

      return !autoLogin(authService, activatedRoute);
    })
  );
};

/**
 * Guard protecting the start-page for authenticated user
 * - Load the route if the user is authenticated
 * - Block the access to the current route if the auto-login flow has started
 * - Navigate to the unprotected start-page ("") otherwise
 * @param activatedRoute
 */
export const authHomeGuard: CanActivateFn = (activatedRoute: ActivatedRouteSnapshot) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  return authService.isUserAuthenticated().pipe(
    first(),
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      }

      if (autoLogin(authService, activatedRoute)) {
        return false;
      }

      router.navigate([""]);
      return false;
    })
  );
};

/**
 * Check if the user should be auto login
 * This function assume the user is not authenticated
 *
 * @param authService
 * @param activatedRoute
 * @returns {boolean} whether the auto-login was triggered or not
 */
function autoLogin(authService: AuthenticationService, activatedRoute: ActivatedRouteSnapshot): boolean {
  const shouldLogin = activatedRoute?.queryParams[Utils.AUTO_LOGIN]?.toString();

  if (shouldLogin?.toLowerCase() === "true") {
    authService.signIn(`${window.location.href}`);
    return true;
  }

  return false;
}
