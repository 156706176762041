import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { first, map } from "rxjs";
import { AuthenticationService } from "../../authentication";

export const authStateLoadedGuard: CanActivateFn = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  return authService.authStateChecked().pipe(
    first(),
    map((hasChecked) => {
      if (hasChecked) {
        return true;
      }

      router.navigate(["/authorize"], { queryParams: { returnUrl: state.url } });
      return false;
    })
  );
};
